/* eslint max-len:0 */

export default [
  {
    id: "bitcoin-ed",
    title: "Programming Bitcoin",
    link: null,
    roles: [
      {
        description:
          "[Attended](https://programmingblockchain.com/certificates/1d68f11829fd44d44294.txt) the 2021 Miami [Programming Blockchain](https://programmingbitcoin.com/programming-blockchain/) seminar with Jimmy Song, where we built out basic wallet software in Python, from finite field implementation through elliptic field cryptography, transaction construction, and propagation on the testnet node network.",
        ended: null,
        location: "Miami",
        name: "Bitcoin Developer",
        started: "2021"
      }
    ]
  },
  {
    id: "bloc-ed",
    link: null,
    roles: [
      {
        description:
          "[Bloc](https://www.bloc.io/users/sasha-klein) is an online web-development apprenticeship that turns students into well-versed developers over the course of 2-3 intensive months. The mentor-guided learning is focused through, and culminates in, a final project of our own design; mine morphed into [MeatUp](/projects#meatup).",
        ended: null,
        location: "San Francisco",
        name: "Web Development Student",
        started: "2012"
      }
    ],
    title: "Bloc"
  },
  {
    id: "harvard",
    kind: "education",
    link: null,
    roles: [
      {
        description:
          "After jumping between Psychology, Econ, Film, and English Lit, I picked the least applicable and graduated with an honors English degree and a screenplay for a thesis.",
        location: "Cambridge, MA",
        name: "BA in English, Cum Laude",
        started: "Sep 2007",
        ended: "Jun 2011"
      }
    ],
    title: "Harvard University"
  }
];
